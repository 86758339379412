import logo from '../logo.svg';
import style from '../styles/modules/Stat.module.css';

function sendMessage(message) {
    const say = message || "담이 바보 아니다~";
    return alert(say);
}

function Stat(props) {
    const { title, data, info, msg } = props;
    return (
        <div className={style.Stat} onClick={() => sendMessage(msg)}>
            <h1 className={style.StatTitle}>{title}</h1>
            <p className={style.StatData}>{data}</p>
            <p className={style.StatInfo}>{info}</p>
        </div>
    );
}

export default Stat;
