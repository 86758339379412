import React, { useState, useEffect } from 'react';
import Loading from './components/Loading';
import Header from './components/Header';
import Stat from './components/Stat';
import Footer from './components/Footer';
import './styles/App.css';

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const loadingTime = 1500;

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, loadingTime);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="App">
      {isLoading && <Loading loadingTime={loadingTime} />}
      {!isLoading && (
        <>
          <Header />
          <div className="StatArea">
            <Stat title="바보" data={47} info="전날 대비 +3 증가" msg="헤실헤실 웃는 중..." />
            <Stat title="빛" data={1} info="도담님이 빛이 있으라하니 빛이 생겼다" msg="도담님이 곧 빛이고 어둠이다." />
            <Stat title="능지" data={-1} info="변동 없음" msg="흐에에에..." />
            <Stat title="호구" data={1} info="유지 중..." msg="응애..."/>
            <Stat title="학원" data={1} info="비ㅣㅣㅣ상" msg="ㄹㅇ개쳐좆댐"/>
          </div>
          <Footer />
        </>
      )}
    </div>
  );
}

export default App;

// serve -s build -l 3002