import logo from '../logo.svg';
import style from '../styles/modules/Footer.module.css';

function Footer() {
    return (
        <div className={style.Footer}>
                <img src={logo} className={style.FooterLogo} alt="logo" />
                <p className={style.CopyrightText}>Copyright &copy; 2024 도담 스탯 - All Rights Reserved.</p>
        </div>
    );
}

export default Footer;
