import logo from '../logo.svg';
import style from '../styles/modules/Header.module.css';

function Header(props) {
    return (
        <div className={style.Header} onClick={() => window.location.reload(true)}>
            <div className={style.TitleArea}>
                <img src={logo} alt="logo" />
                <h1>도담 스탯</h1>
            </div>
        </div>
    );
}

export default Header;
